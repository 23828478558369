import React, { useEffect, useRef, useState } from "react";
import { FaHome } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "antd"; // Use `message` from antd for notifications
import { sendNotification } from "../../redux/actions/employeesAction";

const Communication = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Notification");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [description, setDescription] = useState(""); // Renamed from `message` to `description`
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);

  const { employees } = useSelector((state) => state.employees);

  const handleSendNotification = async () => {
    if (!title || !description || selectedEmployees.length === 0) {
      message.error("Please provide a title, description, and select employees.");
      return;
    }
    const employeeIds = selectedEmployees.map((employee) => employee.id);

    const notificationData = {
      employees: employeeIds,
      title,
      description,
    };
  
    const success = await dispatch(sendNotification(notificationData));
  
    if (success) {
      setTitle(""); // Reset the title
      setDescription(""); // Reset the description
      setSelectedEmployees([]); // Reset employee selection
    }
  };
  

  const handleSelectAll = () => {
    if (selectedEmployees.length === employees.length) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(employees);
    }
  };

  const handleEmployeeSelection = (employee) => {
    if (selectedEmployees.some((emp) => emp.id === employee.id)) {
      setSelectedEmployees(selectedEmployees.filter((emp) => emp.id !== employee.id));
    } else {
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex items-center gap-3 px-6 py-7">
        <div className="flex items-center gap-2 cursor-pointer text-sm text-[#696976]">
          <FaHome />
          <span className="cursor-pointer" onClick={() => navigate("/dashboard")}>
            Dashboard{" "}
          </span>
        </div>
        <span className="text-sm text-[#696976]">/ </span>
        <span className="cursor-pointer text-sm font-semibold text-[#444454]">Communication</span>
      </div>

      {/* Tabs */}
      <div className="flex gap-4 border-b px-6">
        <button
          className={`py-2 px-4 ${
            selectedTab === "Notification" ? "border-b-2 border-blue-500 font-semibold" : ""
          }`}
          onClick={() => setSelectedTab("Notification")}
        >
          Notification
        </button>
        <button
          className={`py-2 px-4 ${
            selectedTab === "Chat" ? "border-b-2 border-blue-500 font-semibold" : ""
          }`}
          onClick={() => setSelectedTab("Chat")}
        >
          Chat
        </button>
      </div>

      {/* Content */}
      <div className="p-6" style={{ width: "50%" }}>
        {selectedTab === "Notification" && (
          <div className="p-5 rounded-[8px] col-span-1 h-fit w-full border shadow-md">
            <div className="mb-4">
              <label htmlFor="password" className="text-[#444454] text-[16px] mb-2">
                Select Employees
              </label>
              <div className="relative mb-4 w-full " ref={dropdownRef}>
                <button
                  className="w-full p-2 border rounded bg-white"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {selectedEmployees.length > 0
                    ? `Selected (${selectedEmployees.length})`
                    : "Click to select employees"}
                </button>

                {dropdownOpen && (
                  <div className="absolute mt-2 w-full bg-white border rounded shadow-md z-10">
                    <div className="flex items-center px-3 py-2 border-b">
                      <input
                        type="checkbox"
                        checked={selectedEmployees.length === employees.length}
                        onChange={handleSelectAll}
                        className="mr-2"
                      />
                      <span>Select All</span>
                    </div>
                    <div className="max-h-40 overflow-y-auto">
                      {employees.map((employee) => (
                        <div
                          key={employee.id} // Use a unique key for each employee
                          className="flex items-center px-3 py-2 hover:bg-gray-100"
                        >
                          <input
                            type="checkbox"
                            checked={selectedEmployees.some((emp) => emp.id === employee.id)}
                            onChange={() => handleEmployeeSelection(employee)}
                            className="mr-2"
                          />
                          <span>{employee.fullName}</span> {/* Display the desired property */}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="text-[#444454] text-[16px] mb-2">
                Title
              </label>
              <input
                type="text"
                id="title"
                className="w-full p-2 border rounded"
                placeholder="Enter the notification title"
                maxLength={30}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    setTitle(e.target.value);
                  }
                }}
                value={title}
              />
              <small className="text-sm text-gray-500">
                {title.length >= 30 ? (
                  <span className="text-red-500">Character limit reached</span>
                ) : (
                  `${title.length}/30 characters`
                )}
              </small>
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="text-[#444454] text-[16px] mb-2">
                Description
              </label>
              <textarea
                id="description"
                rows="4"
                className="w-full p-2 border rounded"
                placeholder="Enter your description here..."
                value={description}
                onChange={(e) => {
                  const text = e.target.value;
                  if (text.length <= 50) {
                    setDescription(text);
                  }
                }}
              ></textarea>

              <small className="text-sm text-gray-500">
                {description.length >= 50 ? (
                  <span className="text-red-500">Character limit reached</span>
                ) : (
                  `${description.length}/50 characters`
                )}
              </small>
            </div>

            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              onClick={handleSendNotification}
            >
              Send Notification
            </button>
          </div>
        )}

        {selectedTab === "Chat" && <div>Chat feature coming soon...</div>}
      </div>
    </>
  );
};

export default Communication;
