import { Routes, Route, Navigate } from "react-router-dom";
import { Authentication, ForgotPassword, ResetPassword, VerifyGoogleAuth } from "./pages/Authentication";
import Index from "./pages/index.js";
import React from "react";
import PrivateRoute from "./components/PrivateRoutes";
import AdminRoutes from "./components/AdminRoutes.js";
import Dashboard from "./pages/Admin/Dashboard.js";
import Employee from "./pages/Admin/Employee.js";
import Client from "./pages/Admin/Client.js";
import Report from "./pages/Admin/Report.js";
import AddEmployee from "./pages/Admin/AddEmployee.js";
import EditEmployee from "./pages/Admin/EditEmployee.js";
import AddClient from "./pages/Admin/AddClient.js";
import EditClient from "./pages/Admin/EditClient.js";
import FaceSheet from "./pages/Admin/FaceSheet.js";
import QuarterlySummary from "./pages/QuarterlySummary/index.js";
import SIRS from "./pages/SIRs/index.js";
import Miscellaneous from "./pages/Miscellaneous/index.js";
import Assessments from "./pages/Assessments/index.js";
import ISPs from "./pages/ISPs/index.js";
import SessionDetails from "./pages/Admin/SessionDetails.js";
import Settings from "./pages/Admin/Settings.js";
import EmployeeSession from "./pages/Admin/EmployeeSession.js";
import ClientSession from "./pages/Admin/ClientSession.js";
import GenerateReport from "./pages/Admin/GenerateReport.js";
import Calendar from "./pages/Admin/Calendar.js";
import ClientCalendar from "./pages/Admin/ClientCalendar.js";
import EmpCalendar from "./pages/Admin/EmpCalendar.js";
import Communication from "./pages/Admin/Communication.js";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/signup" element={<Authentication />} />
        <Route path="/signin" element={<Authentication />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-google-auth" element={<VerifyGoogleAuth />} />

        <Route element={<PrivateRoute />}>
          <Route element={<Index />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/employee" element={<Employee />} />
            <Route path="/employee/sessions/:uuid" element={<EmployeeSession />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/edit-employee/:uuid" element={<EditEmployee />} />
            <Route path="/client" element={<Client />} />
            <Route path="/client/edit/:uuid" element={<EditClient />} />
            <Route path="/client/face-sheet/:uuid" element={<FaceSheet />} />
            <Route path="/client/sessions/:uuid" element={<ClientSession />} />
            <Route path="/add-client" element={<AddClient />} />
            <Route path="/report" element={<Report />} />
            <Route path="/communication" element={<Communication/>} />
            <Route path="/session-details" element={<SessionDetails />} />
            <Route path="/generate-report" element={<GenerateReport />} />
            <Route path="/employee/calendar/:uuid?" element={<EmpCalendar />} />
            <Route path="/client/calendar/:uuid?" element={<ClientCalendar />} />
            <Route path="/client/quarterly-summary/:uuid?" element={<QuarterlySummary />} />
            <Route path="/client/sir/:uuid?" element={<SIRS />} />
            <Route path="/client/misc/:uuid?" element={<Miscellaneous />} />
            <Route path="/client/assessments/:uuid?" element={<Assessments />} />
            <Route path="/client/isps/:uuid?" element={<ISPs />} />
            <Route path="/calendars" element={<Calendar />} />
            <Route path="/settings" element={<Settings />} />
            <Route element={<AdminRoutes />}>{/* <Route path="/admin" element={<AdminSpecific />} /> */}</Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
