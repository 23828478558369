import React, { useEffect, useState } from "react";
import { Progress, Button } from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../redux/actions/employeesAction";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
import axios from "axios";

const EditEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedEmployee, employees } = useSelector((state) => state.employees);
  const [uploading, setUploading] = useState(false);
  const [uploadPer, setUploadPer] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(selectedEmployee?.imageUrl || "");
  const { uuid } = useParams();
  useEffect(() => {
    if (uuid && !selectedEmployee && employees.length > 0) {
      dispatch({
        type: "SET_SELECTED_EMPLOYEE_UUID",
        payload: uuid,
      });
      return;
    }

    if (selectedEmployee) {
      for (const el of document.forms[0].elements) {
        if (el.name && el.name !== "") {
          // For jobstartdate: extract only the date portion
          if (el.name === "jobstartdate" && selectedEmployee[el.name]) {
            el.value = selectedEmployee[el.name].split("T")[0]; // Format as YYYY-MM-DD
          } else if (selectedEmployee[el.name]) {
            el.value = selectedEmployee[el.name];
          }
        }
      }
      if (selectedEmployee.imageUrl) {
        setImagePreviewUrl(selectedEmployee.imageUrl);
      }
    }
  }, [selectedEmployee, employees]);

  const handleEditEmployee = async (e) => {
    e.preventDefault();
    let data = {};
    let emergencyContacts = [];

    // Extract form data and emergency contact inputs
    for (const el of e.currentTarget.elements) {
      if (el.name) {
        if (el.name.startsWith("emergencyContacts")) {
          const [_, index, key] = el.name.match(/emergencyContacts\[(\d+)\]\.(\w+)/) || [];
          if (index !== undefined && key) {
            if (!emergencyContacts[index]) emergencyContacts[index] = {};
            emergencyContacts[index][key] = el.value;
          }
        } else {
          data[el.name] = el.value; // General form inputs
        }
      }
    }

    // Add emergencyContacts JSON data
    data.emergencyContacts = emergencyContacts;
    for (const el of e.currentTarget.elements) {
      // console.log(el.name);
      if (el.name && el.name != "") {
        data[el.name] = el.value;
      }
    }
    if (data.jobstartdate) {
      data.jobstartdate = new Date(data.jobstartdate).toISOString();
    } else {
      data.jobstartdate = null;
    }


    console.log(data);
    const res = await dispatch(await update(data));
    if (res) {
      navigate("/employee");
    }
  };
  const fetchUploadUrl = async (file) => {
    const fileName = file.name;
    // Step 1: Get the presigned URL for uploading
    const { data } = await authAxios.post("/assets/create-profile-img", {
      fileName,
      fileType: file.type,
      id: selectedEmployee.id,
      model: "employees",
    });
    return data;
  };

  const handleUploadImage = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    const data = await fetchUploadUrl(file);
    console.log(data);
    const result = await axios.put(data.presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let precentage = Math.floor((loaded * 100) / total);
        console.log("options");
        console.log(precentage);
        setUploadPer(precentage);
      },
    });
    document.getElementById("imageKey").value = data.key;
    setImagePreviewUrl(URL.createObjectURL(file));
    setUploading(false);
  };
  return (
    <>
      <SearchBar />
      <div className="flex flex-wrap items-center gap-3 px-6 py-4">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard </span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] text-[#696976]" onClick={() => navigate("/employee")}>
          Employee
        </span>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-semibold text-[#444454]">Edit Employee Information</span>
      </div>

      <h1 className="px-6 py-2 text-[#161D20] font-bold text-[24px]">Edit Employee</h1>
      <form action="" onSubmit={handleEditEmployee}>
        <div className="flex flex-col w-full md:flex-row ">
          <div className="px-4 md:w-2/3">
            <input type="hidden" name="id" />
            {/* <div className="p-6 grid items-center justify-between flex-wrap grid-cols-2  gap-4 border rounded-[10px] shadow-md"> */}
            <div className="p-6 grid   items-center justify-between gap-4 border rounded-[10px] shadow-md md:grid-cols-2">
              <div className="flex flex-col w-full gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">First Name</label>
                <input type="text" placeholder="Enter your first name" name="firstName" className=" w-full px-4 py-2 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Last Name</label>
                <input type="text" placeholder="Enter your last name" name="lastName" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Email</label>
                <input type="text" placeholder="Enter your email" name="email" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Phone Number</label>
                <input type="text" placeholder="Enter your number" name="number" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Employee Number</label>
                <input type="text" placeholder="Enter your employee Number" name="employeeNumber" className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Address</label>
                <input type="text" placeholder="Enter your address" name="address" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              {/* <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Zip Code</label>
                <input type="text" placeholder="Enter your zip code" name="zipCode" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">State</label>
                <input type="text" placeholder="Enter your state" name="state" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div> */}
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Country</label>
                <select className="w-full bg-white px-2 border h-10 outline-none  rounded-[8px] font-[500] text-[#444454]" name="country" placeholder="Enter your country">
                  <option value="USA">USA</option>
                  <option value="Canada">Canada</option>
                  <option value="India">India</option>
                </select>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Job Title</label>
                <input type="text" placeholder="Enter your job title" name="jobtitle" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col w-full gap-2 col-span-full">
                <label className="text-[16px] font-[400] text-[#444454]">Status</label>
                <input
                  type="text"
                  placeholder="Enter your status"
                  name="jobstatus"
                  className="w-full px-4 py-2 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>
              <div className="col-span-full">
                <span className="cursor-pointer text-[20px] font-bold text-[#444454]">Emergency Contact</span>
              </div>

              {/* Ensure emergencyContacts always has at least one entry */}
              {(selectedEmployee?.emergencyContacts?.length
                ? selectedEmployee.emergencyContacts
                : [{ name: "", phone: "", relationship: "" }]
              ).map((contact, index) => (
                <div key={index} className="grid grid-cols-2 gap-4 col-span-full">
                  {/* Name Field */}
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Name</label>
                    <input
                      type="text"
                      placeholder="Enter emergency contact person name"
                      name={`emergencyContacts[${index}].name`}
                      defaultValue={contact.name || ""}
                      className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>

                  {/* Phone Field */}
                  <div className="flex flex-col gap-2">
                    <label className="text-[16px] font-[400] text-[#444454]">Phone</label>
                    <input
                      type="text"
                      placeholder="Enter emergency contact person phone"
                      name={`emergencyContacts[${index}].phone`}
                      defaultValue={contact.phone || ""}
                      className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>

                  {/* Relationship Field */}
                  <div className="flex flex-col gap-2 col-span-full">
                    <label className="text-[16px] font-[400] text-[#444454]">Relationship</label>
                    <input
                      type="text"
                      placeholder="Enter relationship"
                      name={`emergencyContacts[${index}].relationship`}
                      defaultValue={contact.relationship || ""}
                      className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]"
                    />
                  </div>
                </div>
              ))}


              <div className="col-span-full">
                <span className="cursor-pointer text-[20px] font-bold text-[#444454]">Employment Details</span>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Start Date</label>
                <input type="date" placeholder="Enter date" name="jobstartdate" className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Type</label>
                <input type="text" placeholder="Enter your type" name="employeeType" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Primary Role</label>
                <input type="text" placeholder="Enter your role" name="primaryrole" className="w-full px-3 py-2 outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-[16px] font-[400] text-[#444454]">Assigned Department</label>
                <input type="text" placeholder="Enter your department" name="department" className="w-full px-4 py-2  outline-none border rounded-[8px] text-[#444454]" />
              </div>
              <div className="flex flex-col w-full gap-2 col-span-full">
                <label className="text-[16px] font-[400] text-[#444454]">Manager Name</label>
                <input
                  type="text"
                  placeholder="Enter your manager name"
                  name="managername"
                  className="w-full px-4 py-2 outline-none border rounded-[8px] text-[#444454]"
                />
              </div>


            </div>
            <div className="px-12 ">
              <button type="submit" disabled={uploading} className="bg-[#3795F7] px-6 py-2 text-white  font-[500] rounded-[8px] disabled:opacity-70 mt-6">
                Update Employee Information
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center md:w-1/3 mr-4 mb-auto">
            <div className="md:p-0 my-4 justify-center">
              <label
                htmlFor="imgInput"
                className="cursor-pointer hover:shadow-md p-4 rounded-[8px] w-full outline-dashed outline-gray-200 md:w-fit mx-auto flex flex-col items-center justify-center gap-2">
                {uploading ? (
                  <Progress type="circle" percent={uploadPer} />
                ) : imagePreviewUrl ? (
                  <div className="relative text-center">
                    <img src={imagePreviewUrl} alt="Preview" style={{ width: 300, position: "relative" }} />
                    {/* <EditOutlined style={{ position: "absolute", top: 0, right: 0 }} /> */}
                    <span className="text-gray-400 text-sm p-2">Click to update profile picture</span>
                  </div>
                ) : (
                  <>
                    <UploadOutlined style={{ fontSize: "24px" }} />
                    <span>Click to Upload profile picture</span>
                  </>
                )}
                <input type="file" accept="image/*" id="imgInput" name="image" className="hidden" onChange={handleUploadImage} />
                <input type="text" required={false} id="imageKey" name="imageKey" className="hidden" />
              </label>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditEmployee;
