import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import ClientSideBar from "../../components/ClientSideBar";
import { FaHome } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
import { Button, Input, message, Progress, Select, TimePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { CiTrash } from "react-icons/ci";
import axios from "axios";


function formatDate(
  dateString,
  timeZone,
  format = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }
) {
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat("en-US", { ...format, timeZone });
  return formatter.format(date);
}
function ChatMessage({ text, speaker, img }) {
  const isCounselor = speaker === "counselor";
  return (
    <div className={`flex items-end ${isCounselor ? "justify-end" : "justify-start"} my-2`}>
      {!isCounselor && <img src={img ? img : "/images/user.png"} className="h-8 w-8 rounded-full mr-2" />}
      <div
        className={`relative max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl px-4 py-2 ${isCounselor ? "bg-blue-100" : "bg-green-100"} rounded-t-lg ${isCounselor ? "rounded-bl-lg" : "rounded-br-lg"}`}>
        <p className={`${isCounselor ? "text-left" : "text-left"} text-sm sm:text-base`}>{text}</p>
      </div>
      {isCounselor && <img src={img ? img : "/images/user.png"} alt="Counselor" className="h-8 w-8 rounded-full ml-2" />}
    </div>
  );
}
const SessionDetails = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedClient, clients } = useSelector((state) => state.clients);
  useEffect(() => {
    if (uuid && !selectedClient && clients.length > 0) {
      dispatch({
        type: "SET_SELECTED_CLIENT_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedClient) return;
  }, [selectedClient, clients]);

  const [selectedIndexes, setSelectedIndexes] = useState({ employee: null, session: null });
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [allSessions, setAllSessions] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false); // Track "Add New" mode
  const [uploading, setUploading] = useState(false);
  const [uploadPer, setUploadPer] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(selectedClient?.imageUrl || "");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState();


  const { employees } = useSelector((state) => state.employees);
  const [offset, setOffset] = useState(0); // Current offset
  const [hasMore, setHasMore] = useState(true); // Whether more data is available
  const [loading, setLoading] = useState(false); // Loading indicator
  const [SessionData, setSessionData] = useState({

    notes: [""],
  });

  const [curDateTime, setCurDateTime] = useState({
    startDate: "",
    startTime: null,
    endTime: null,
    timeValue: null,
  });


  const handleUploadAudio = (e) => {
    setUploading(false);
    setUploadPer(0);
    const file = e.target.files[0];

    setSelectedFile(file); // Store the selected file in state
    setImagePreviewUrl(URL.createObjectURL(file)); // Preview the image
  };
  const limit = 20; // Number of items per API call

  const handleSelectEmp = async (id, offsetParam = offset) => {
    if (loading || !hasMore || !id) return;
  
    if (offsetParam === 0) {
      setAllSessions([]); // Clear all previous sessions if starting fresh
    }
  
    setLoading(true);
    try {
      const resdata = await authAxios.post(`/clients/get-all-sessions`, {
        clientId: id,
        offset: offsetParam,
        limit,
      });
  
      const newSessions = resdata.data.sessions || [];
      const totalCount = resdata.data.total || 0;
  
      setAllSessions((prevSessions) =>
        offsetParam === 0 ? newSessions : [...prevSessions, ...newSessions]
      );
      setOffset(offsetParam + newSessions.length); // Update offset to the next value
      setHasMore(newSessions.length > 0 && allSessions.length + newSessions.length < totalCount);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (loading || !hasMore || !selectedClient?.id) return;
  
    const sessionListContainer = document.getElementById("sessionListContainer");
    if (sessionListContainer) {
      const { scrollTop, scrollHeight, clientHeight } = sessionListContainer;
  
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        handleSelectEmp(selectedClient.id);
      }
    }
  }, [loading, hasMore, selectedClient]);
  
  const handleAddNewSession = () => {
    setIsAddingNew(true); // Show the "Add New" form
    setSelectedIndexes({ ...selectedIndexes, session: null });

    // Reset form fields
    setSessionData({
      notes: [""],
    });

    setCurDateTime({
      startDate: "",
      startTime: null,
      endTime: null,
      timeValue: null,
    });
    setSelectedFile(null); // Reset uploaded file
    setImagePreviewUrl(""); // Clear audio preview
    setSelectedEmployee(null); // Reset selected employee
  };

  const handleAddGoal = () => {
    const updatedGoals = [...SessionData.notes, ""];
    setSessionData({ ...SessionData, notes: updatedGoals });
  };
  const handleRemoveGoal = (index) => {
    const updatedGoals = SessionData.notes.filter((_, i) => i !== index);
    setSessionData({ ...SessionData, notes: updatedGoals });
  };
  const handleGoalsChange = (index, value) => {
    const updatedGoals = [...SessionData.notes];
    updatedGoals[index] = value;
    setSessionData({ ...SessionData, notes: updatedGoals });
  };
  const fetchUploadUrl = async (file, sessionId, email, requestType = "upload",assetId) => {
    const fileName = file.name;
    // Step 1: Get the presigned URL for uploading
    const { data } = await authAxios.post("/assets/create-session-recording", {
      fileName: file.name,
      fileType: file.type,
      sessionId: sessionId,
      email: email,
      requestType: requestType,
      assetId:assetId
    });
    return data;
  };


  const uploadImage = async (file, sessionId, email) => {
    try {
      setUploading(true);
      const uploadUrlData = await fetchUploadUrl(file, sessionId, email);
  
      await axios.put(uploadUrlData.presignedUrl, file, {
        headers: { "Content-Type": file.type },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setUploadPer(Math.floor((loaded * 100) / total));
        },
      });
      const audioRes = await fetchUploadUrl(file, sessionId, email,"view",uploadUrlData.asset.id);
      return audioRes.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to upload audio.");
    } finally {
      setUploading(false);
      setUploadPer(0);
      setSelectedFile(null);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate notes or audio file
    const hasValidNotes = SessionData.notes.some((note) => note?.trim() !== "");
    if (!hasValidNotes && !selectedFile) {
      message.error("Please provide at least one note or upload an audio file!");
      return;
    }
    if (!curDateTime.startDate || !curDateTime.startTime || !curDateTime.endTime) {
      message.error("Please fill in all required fields!");
      return;
    }

    // Extract only the time part from startTime and endTime
    const startTimeFormatted = moment(curDateTime.startTime).format("HH:mm:ss");
    const endTimeFormatted = moment(curDateTime.endTime).format("HH:mm:ss");

    // Combine the selected date with the formatted times
    const startDateTime = new Date(`${curDateTime.startDate}T${startTimeFormatted}`).toISOString();
    const endDateTime = new Date(`${curDateTime.startDate}T${endTimeFormatted}`).toISOString();
    const payload = {
      startDateTime,
      endDateTime,
      notes: SessionData.notes.filter((note) => note?.trim() !== ""),
      clientId: selectedClient.id, // Ensure selectedClient has .id
      employeeId: selectedEmployee, // Ensure selectedEmployee is valid
    };
    try {
      setUploading(true);

      // Send JSON payload to the API to create the session
      const res = await authAxios.post("/sessions/create", payload);

      const sessionId = res.data.session.id; // Assuming API returns session ID
      const newSession = res.data.session; // Assuming API returns the session object
      const email = clients.find((client) => client.id === selectedClient.id)?.email;

      console.log("New session:", newSession);

      // If audio file is selected, call the `uploadImage` function
      let audioUrl = null;
      if (selectedFile) {
       audioUrl=  await uploadImage(selectedFile, sessionId, email);
      }

      message.success("Session created successfully!");
      setIsAddingNew(false); // Reset form after successful submission
      setUploading(false); // Stop uploading state
      setAllSessions((prevSessions) => [newSession, ...prevSessions]);
      setCurrentSession({...newSession,audioUrl}); // Set the newly created session as current
      setSelectedIndexes({ ...selectedIndexes, session: 0 }); // Highlight new session   
      setOffset(0); // Reset the offset to fetch from the beginning
      setHasMore(true); // Reset the `hasMore` state
      handleSelectEmp(selectedClient.id, 0);
    } catch (error) {
      console.error("Error creating session:", error);
      message.error("Failed to create session.");
      setUploading(false);
    }
  };







  // useEffect(() => {
  //   if (selectedClient && selectedClient?.id) {
  //     handleSelectEmp(selectedClient.id);
  //   }
  // }, [selectedClient]);
  useEffect(() => {
    // Change body style on mount
    document.body.style.overflow = "hidden";

    // Revert body style on unmount
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    if (selectedClient?.id) {
      // Reset states for a new client
      setAllSessions([]);
      setOffset(0);
      setHasMore(true);
      setLoading(false);
  
      // Fetch the first set of sessions
      handleSelectEmp(selectedClient.id);
    }
  }, [selectedClient]);
  
  useEffect(() => {
    const sessionListContainer = document.getElementById("sessionListContainer");
  
    let scrollTimeout = null;
  
    const onScroll = () => {
      if (scrollTimeout) clearTimeout(scrollTimeout);
  
      // Debounce the scroll event
      scrollTimeout = setTimeout(() => {
        handleScroll();
      }, 300); // Adjust the debounce delay as necessary
    };
  
    if (sessionListContainer) {
      sessionListContainer.addEventListener("scroll", onScroll);
      return () => sessionListContainer.removeEventListener("scroll", onScroll);
    }
  }, [handleScroll]);
  
  return (
    selectedClient && (
      <div className="overflow-hidden">
        <div className="flex items-center gap-3 px-6 pt-6 ">
          <div className="flex items-center gap-2 cursor-pointer text-[16px] font-[400] text-[#696976]">
            <FaHome />
            <span className="cursor-pointer">Dashboard </span>
          </div>{" "}
          <span className="text-[16px] text-[#696976]">/ </span>
          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Client</span>
          <span className="text-[16px] text-[#696976]">/ </span>
          <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">Session Details</span>
        </div>
        {selectedClient && (
          <div className={`flex items-center justify-between mx-8 w-1/2 py-3 px-4 `}>
            <div className="flex items-center gap-2  w-full">
              <div className="aspect-w-1 aspect-h-1 overflow-hidden  h-16 w-16 rounded-full">
                <img src={selectedClient.imageUrl ? selectedClient.imageUrl : "/images/user.png"} alt="employee" className="object-cover w-full h-full" />
              </div>
              <div>
                <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold"> {selectedClient?.fullName || "Loading..."}</h1>
                <span className="text-[#444454] text-[1rem]">{selectedClient.uci}</span>
                {selectedClient?.email && <span className="text-[#444454] text-[1rem] "> - {selectedClient?.email && selectedClient.email}</span>}
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-10 gap-1 px-1 h-[85vh] overflow-hidden">
          <ClientSideBar tab={"Session"} uuid={selectedClient.uuid} />
          {/* left side */}
          <div className="col-span-2 gap-4 rounded-[8px] border shadow-md h-[80vh] overflow-auto" id="sessionListContainer">
            {/* <div className="border shadow-md rounded-[8px] "> */}
            <div
              className="p-3 text-center w-full cursor-pointer rounded transition duration-200"
              onClick={handleAddNewSession}
            >
              <p className="text-sm font-medium">+ Add New</p>
            </div>


            {allSessions.map((item, index) => (
              <div
                key={index}

                onClick={async () => {
                  setIsAddingNew(false); // Reset Add New state
                  setCurrentSession(null);
                  setSessionLoading(true);
                  // sleep for 1 sec
                  await new Promise((resolve) => setTimeout(resolve, 250));
                  // console.log(item);
                  setSelectedIndexes({
                    ...selectedIndexes,
                    session: index,
                  });
                  setSessionLoading(false);

                  setCurrentSession(item);
                }}
                className={`flex items-center justify-between py-3 px-4 border shadow-sm hover:shadow-md cursor-pointer ${index == 0 && "rounded-t-[8px]"} ${selectedIndexes.session === index && "bg-gray-200"
                  }`}>
                <div className="flex items-center gap-2">
                  {item.id}
                  <img src={item?.client?.imageUrl ? item?.client?.imageUrl : "/images/user.png"} alt="employee" className="w-10 h-10 rounded-full" />
                  <div>
                    <p className="text-sm">{new Date(item.startTime).toDateString()}</p>
                  </div>
                </div>
              </div>
            ))}
            {empLoading && <Loader className="mt-40" />}

            {!empLoading && allSessions.length == 0 && (
              <div className="text-center w-full my-auto mt-12">
                <p className="text-sm text-gray-700">No sessions found</p>
              </div>
            )}
            {/* </div> */}
          </div>

          {/* right side */}
          <div className="col-span-6 p-4 border shadow-md h-[80vh] overflow-y-scroll">
            {/* Conditional Rendering */}
            {isAddingNew ? (
              <div>
                <h1 className="text-2xl font-bold mb-4">Add New Session</h1>
                <form onSubmit={handleSubmit}>
                  <div
                    className="mx-auto w-full px-auto justify-center text-center"
                    onClick={() => {
                      document.getElementById("imgInput").click();
                    }}>
                    {uploading ? (
                      <Progress type="circle" percent={uploadPer} />
                    ) : imagePreviewUrl ? (
                      <div className="flex flex-col text-center">
                        <audio controls className="w-full p-2  ">
                          <source src={imagePreviewUrl} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                        <span className="text-gray-400 text-sm p-2">Click to change audio</span>
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center  mx-auto bg-slate-100 p-3 rounded-md cursor-pointer mb-4">
                        <div className="w-fit mx-auto">
                          <UploadOutlined style={{ fontSize: "20px" }} />
                        </div>
                        <span className="text-center">Click to upload audio</span>
                      </div>
                    )}
                    <input type="file" id="imgInput" name="image" className="hidden" onChange={handleUploadAudio} />
                  </div>
                  <div className="mb-4">
                    <label className="block font-bold mb-1">Select a employee</label>
                    <Select
                      showSearch
                      placeholder="Select an employee"
                      value={selectedEmployee}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.label.props.children[0].toLowerCase().includes(input.toLowerCase())}
                      style={{ height: "40px" }} // Add custom height
                      className="w-full"
                      onChange={(e) => setSelectedEmployee(e)}
                      options={employees.map((employee) => ({
                        value: employee.id,
                        label: (
                          <>
                            {employee.fullName} <span className="text-gray-500 text-xs">{employee.email}</span>
                          </>
                        ),
                      }))}
                    />
                  </div>
                  <div className="mb-4">
                    <div className="mt-6 flex items-center gap-4">
                      <label className="block font-bold mb-1">Starting Date: </label>
                      <input
                        type="date"
                        id="startDate"
                        className="border py-1.5 rounded-lg text-sm px-3"
                        onChange={(e) => setCurDateTime({ ...curDateTime, startDate: e.target.value })}
                        value={curDateTime.startDate}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="mt-6 flex items-center gap-4">
                      <label className="block font-bold mb-1">Select time </label>

                      <TimePicker.RangePicker
                        use12Hours
                        format="h:mm a"
                        value={curDateTime.timeValue}
                        onChange={(e) =>
                          setCurDateTime({
                            ...curDateTime,
                            startTime: e[0]?.toDate(),
                            endTime: e[1]?.toDate(),
                            timeValue: e,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-4 mb-8">
                    <div className="flex mb-3 justify-between items-center">
                      <h3>Notes</h3>
                      <Button onClick={handleAddGoal}>+ Add More</Button>
                    </div>

                    {SessionData.notes.map((goal, index) => (
                      <div key={index} className="flex mb-1">
                        <Input value={goal} onChange={(e) => handleGoalsChange(index, e.target.value)} style={{ width: "calc(100% - 60px)", display: "inline-block" }} />
                        <button onClick={() => handleRemoveGoal(index)} style={{ marginLeft: "10px" }}>
                          <CiTrash />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end gap-2">
                    <button type="button" onClick={() => setIsAddingNew(false)} className="bg-gray-300 px-4 py-2 rounded">Cancel</button>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Save Changes</button>
                  </div>
                </form>
              </div>
            ) : sessionLoading ? (
              <Loader className="mt-40" />
            ) :
              currentSession ? (
                <div>
                  <div className="rounded-[8px] mb-16 h-screen ">
                    <h2 className="pb-2 text-sm text-gray-500">Session ID: {currentSession?.id}</h2>
                    {currentSession?.audioUrl && (
                      <div className="bg-[#D9D9D9] flex items-center justify-center rounded-[8px] px-1 relative ">
                        <audio controls className="w-full p-2  ">
                          <source src={currentSession.audioUrl} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    )}

                    {/* employee details */}
                    <div className="w-full p-2 mt-3 ">
                      {/* name */}
                      <div className="flex items-center justify-between">
                        <h1 className="text-[24px] font-bold text-[#161D20]">{currentSession.employee.fullName}</h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      <p className="text-[16px] mt-0  text-[#444454CC]">Counselor</p>

                      {/* time & date */}
                      <div className="flex flex-row gap-4 mt-0">
                        <span className="text-[16px]  text-[#444454CC]">{currentSession.employee.email}</span>
                      </div>
                      {/* role */}
                    </div>
                    {/* Client details */}
                    <div className="w-full p-2 mt-3 ">
                      {/* name */}
                      <div className="flex items-center justify-between">
                        <h1 className="text-[24px] font-bold text-[#161D20]">{selectedClient.fullName}</h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      <p className="text-[16px] mt-0  text-[#444454CC]">Consumer</p>

                      {/* time & date */}
                      <div className="flex flex-row gap-4 mt-0">
                        <span className="text-[16px]  text-[#444454CC]">{selectedClient.uci}</span>
                        {selectedClient?.email && <span className="text-[16px]  text-[#444454CC]">{selectedClient?.email && selectedClient.email}</span>}
                      </div>
                      {/* role */}
                    </div>

                    {/* Audio Sharing */}
                    <div className="w-full p-2 mt-3 ">
                      {/* name */}
                      <div className="flex items-center justify-between">
                        <h1 className="text-[24px] font-bold text-[#161D20]">Session Info</h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      {/* time  */}
                      <div className="flex flex-col mt-1">
                        <div className="flex flex-row gap-10">
                          <span className="text-[16px]  text-[#444454CC]">Started At :</span>
                          <span className="text-[16px]  text-[#444454CC]">
                            {/* <p>Started At: {formatDate(currentSession.startTime, currentSession.timeZone)}</p>
                          <p>Ended At: {formatDate(currentSession.endTime, currentSession.timeZone)}</p> */}
                            {formatDate(currentSession.startTime, currentSession.timeZone)}
                            {/* {new Date(currentSession.startTime).toDateString() + " " + new Date(currentSession.startTime).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")} */}
                          </span>
                        </div>
                        <div className="flex flex-row gap-10">
                          <span className="text-[16px]  text-[#444454CC]">Ended At&nbsp;:</span>
                          <span className="text-[16px]  text-[#444454CC]">
                            &nbsp;&nbsp;
                            {formatDate(currentSession.endTime, currentSession.timeZone)}
                            {/* {new Date(currentSession.endTime).toDateString() + " " + new Date(currentSession.endTime).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")} */}
                          </span>
                        </div>
                        <div className="flex flex-row gap-10">
                          <span className="text-[16px]  text-[#444454CC]">TimeZone&nbsp;:</span>
                          <span className="text-[16px]  text-[#444454CC]">{currentSession.timeZone}</span>
                        </div>
                      </div>
                    </div>

                    {currentSession?.notes && currentSession.notes.length > 0 && (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Notes</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <ul className=" w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                          {currentSession.notes?.map((item, index) => (
                            <li itemType="" className="text-base list-item text-[#3c3c43cc]" key={index}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {/* Your Question  */}
                    {currentSession?.intelligence?.summary && (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Summary</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <p className="text-[14px] text-[#444454CC] ml-2">{currentSession?.intelligence?.summary}</p>
                      </div>
                    )}

                    {currentSession.intelligence?.topics && currentSession.intelligence?.topics.length > 0 && (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Topics</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <div className="w-full flex flex-wrap">
                          {currentSession.intelligence?.topics?.map((item, index) => (
                            <p key={index} className="text-[14px] w-fit text-[#444454CC] m-1 rounded-full bg-slate-100 px-2 py-1">
                              {item}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="w-full p-2 mt-3 ">
                      {/* heading */}
                      {(currentSession.intelligence?.data?.counselor && currentSession.intelligence?.data?.counselor?.length > 0) ||
                        (currentSession.intelligence?.data?.consumer && currentSession.intelligence?.data?.consumer?.length > 0) ? (
                        <>
                          <div className="flex items-center justify-between">
                            <h1 className="text-[24px] font-bold text-[#161D20]">Data</h1>
                            {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                          </div>
                          <p className="mt-2">Counselor: </p>
                          <ul className=" w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                            {currentSession.intelligence?.data?.counselor?.map((item, index) => (
                              <li itemType="" className="text-[14px]  list-item text-[#444454CC]" key={index}>
                                {item}
                              </li>
                            ))}
                          </ul>
                          <p className="mt-2">Consumser: </p>
                          <ul className="flex flex-col w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                            {currentSession.intelligence?.data?.consumer?.map((item, index) => (
                              <li className="text-[14px]  text-[#444454CC]" key={index}>
                                {item}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {(currentSession.intelligence?.action?.counselor && currentSession.intelligence?.action?.counselor?.length > 0) ||
                      (currentSession.intelligence?.action?.consumer && currentSession.intelligence?.action?.consumer?.length > 0) ? (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Actions</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <p className="mt-2">Counselor: </p>
                        <ul className="flex flex-col w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                          {currentSession.intelligence?.action?.counselor?.map((item, index) => (
                            <li className="text-[14px] text-[#444454CC]" key={index}>
                              {item}
                            </li>
                          ))}
                        </ul>
                        <p className="mt-2">Consumser: </p>
                        <ul className="flex flex-col w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                          {currentSession.intelligence?.action?.consumer?.map((item, index) => (
                            <li className="text-[14px]  text-[#444454CC]" key={index}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(currentSession.intelligence?.response?.counselor && currentSession.intelligence?.response?.counselor?.length > 0) ||
                      (currentSession.intelligence?.response?.consumer && currentSession.intelligence?.response?.consumer?.length > 0) ? (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Responses</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <p className="mt-2">Counselor: </p>
                        <ul className="flex flex-col w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                          {currentSession.intelligence?.response?.counselor?.map((item, index) => (
                            <li className="text-[14px] text-[#444454CC]" key={index}>
                              {item}
                            </li>
                          ))}
                        </ul>
                        <p className="mt-2">Consumser: </p>
                        <ul className="flex flex-col w-full gap-1 px-1 ml-4" style={{ listStyleType: "disc" }}>
                          {currentSession.intelligence?.response?.consumer?.map((item, index) => (
                            <li className="text-[14px]  text-[#444454CC]" key={index}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                    {currentSession?.intelligence?.sentiment && (
                      <div className="w-full p-2 mt-3 ">
                        {/* heading */}
                        <div className="flex items-center justify-between">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Sentiments</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <p className="mt-2">
                          Overall Sentiment: <span className="font-semibold"> {String(currentSession.intelligence?.sentiment).toLocaleUpperCase()} </span>
                        </p>
                        <p className="mt-1 text-sm">
                          Neutral: <span className="font-semibold"> {String(currentSession.intelligence?.sentiments?.neutral).toLocaleUpperCase()} </span>
                        </p>
                        <p className="mt-1 text-sm">
                          Positive: <span className="font-semibold"> {String(currentSession.intelligence?.sentiments?.positive).toLocaleUpperCase()} </span>
                        </p>
                        <p className="mt-1 text-sm">
                          Negative: <span className="font-semibold"> {String(currentSession.intelligence?.sentiments?.negative).toLocaleUpperCase()} </span>
                        </p>
                      </div>
                    )}
                    {currentSession.intelligence?.transcript && currentSession.intelligence?.transcript.length > 0 && (
                      <>
                        <div className="flex items-center justify-between mt-4">
                          <h1 className="text-[24px] font-bold text-[#161D20]">Transcript:</h1>
                          {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                        </div>
                        <div className="p-4">
                          <div className="flex flex-col">
                            {currentSession.intelligence?.transcript.map((message, index) => (
                              <ChatMessage key={index} text={message.text} speaker={message.speaker} img={message.speaker == "consumer" ? selectedClient?.imageUrl : currentEmployee?.imageUrl} />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (

                <p className="text-center text-gray-500 mt-20">Select a session or add a new one.</p>

              )}
          </div>
        </div>
      </div>
    )
  );
};

export default SessionDetails;
