const initState = {
  loading: false,
  selectedEmployeeId: null,
  selectedEmployee: null,
  selectedEmpCalendar: [],
  employees: [],
  notifications: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_ALL_EMPLOYEES":
      return {
        ...state,
        employees: action.payload,
      };
    case "CREATE_EMPLOYEE":
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };
    case "UPDATE_EMPLOYEE":
      return {
        ...state,
        employees: state.employees.map((emp) => {
          if (emp.id === action.payload.id) {
            return action.payload;
          }
          return emp;
        }),
      };
    case "SET_SELECTED_EMPLOYEE":
      return {
        ...state,
        selectedEmployeeId: action.payload.id,
        selectedEmployee: action.payload,
      };
    case "SET_SELECTED_EMPLOYEE_UUID":
      // payload is uuid find using that
      return {
        ...state,
        selectedEmployeeId: state.employees.find(
          (emp) => emp.uuid === action.payload
        ).id,
        selectedEmployee: state.employees.find(
          (emp) => emp.uuid === action.payload
        ),
      };
    case "SET_SELECTED_EMP_CALENDAR":
      return {
        ...state,
        selectedEmpCalendar: action.payload,
      };
      case "SEND_NOTIFICATION":
        return {
          ...state,
          notifications: [...state.notifications, action.payload],
        };
    default:
      return state;
  }
};
