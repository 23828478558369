import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { FaHome } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import authAxios from "../../redux/actions/AxiosInstance/authenticated";
import ImageDS from "../../components/utils/ImageDS";
function ChatMessage({ text, speaker, img }) {
  console.log(speaker);
  const isCounselor = speaker === "counselor";
  return (
    <div
      className={`flex items-end ${
        isCounselor ? "justify-end" : "justify-start"
      } my-2`}
    >
      {!isCounselor && (
        <img
          src={img ? img : "/images/user.png"}
          alt="consumer"
          className="h-8 w-8 rounded-full mr-2"
        />
      )}
      <div
        className={`relative max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl px-4 py-2 ${
          isCounselor ? "bg-blue-100" : "bg-green-100"
        } rounded-t-lg ${isCounselor ? "rounded-bl-lg" : "rounded-br-lg"}`}
      >
        <p
          className={`${
            isCounselor ? "text-left" : "text-left"
          } text-sm sm:text-base`}
        >
          {text}
        </p>
      </div>
      {isCounselor && (
        <img
          src={img ? img : "/images/user.png"}
          alt="Counselor"
          className="h-8 w-8 rounded-full ml-2"
        />
      )}
    </div>
  );
}
const SessionDetails = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { selectedEmployee, employees } = useSelector(
    (state) => state.employees
  );
  useEffect(() => {
    // console.log(selectedClient);
    if (uuid && !selectedEmployee && employees.length > 0) {
      dispatch({
        type: "SET_SELECTED_EMPLOYEE_UUID",
        payload: uuid,
      });
      return;
    }
    if (!selectedEmployee) return;
  }, [selectedEmployee, employees]);
  const [selectedIndexes, setSelectedIndexes] = useState({
    employee: null,
    session: null,
  });
  const [currentClient, setcurrentClient] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [allSessions, setAllSessions] = useState([]);
  const [clientLoading, setclientLoading] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const handleSelectEmp = async (item) => {
    setclientLoading(true);

    setCurrentSession(null);
    setSelectedIndexes({ employee: null, session: null });
    // call api
    const resdata = await authAxios.post(`/employees/get-all-sessions`, {
      empId: selectedEmployee.id,
    });
    setAllSessions(resdata.data.sessions);
    // console.log(resdata.data.sessions);
    setclientLoading(false);
  };
  useEffect(() => {
    if (selectedEmployee && selectedEmployee?.id) {
      handleSelectEmp(selectedEmployee.id);
    }
  }, [selectedEmployee]);
  return (
    <div className="overflow-hidden">
      <div className="flex items-center gap-3 px-6 pt-6 ">
        <div className="flex items-center gap-2 cursor-pointer text-[16px] font-[400] text-[#696976]">
          <FaHome />
          <span className="cursor-pointer">Dashboard</span>
        </div>
        <span className="text-[16px] text-[#696976]">/ </span>

        <span className="cursor-pointer text-[16px] font-[600]  text-[#444454]">
          Session Details
        </span>
      </div>

      {selectedEmployee && (
        <div
          className={`flex items-center justify-between mx-8 w-1/4 py-3 px-4 `}
        >
          <div className="flex items-center gap-2">
            <div className="aspect-w-1 aspect-h-1 overflow-hidden w-16 h-16 rounded-full">
              {/* <img
                src={
                  selectedEmployee.imageUrl
                    ? selectedEmployee?.imageUrl
                    : "/images/user.png"
                }
                alt="employee"
                className="object-cover w-full h-full"
              /> */}
              <ImageDS
                imageKey={selectedEmployee.imageKey}
                borderRadius={20}
                className="object-cover w-full h-full"
              />
            </div>
            <div>
              <h1 className="text-[#161D20] text-[1.5rem] -mb-1 font-bold">
                {selectedEmployee.fullName}
              </h1>
              <p className="text-[#444454] text-[1rem]">
                {selectedEmployee.email}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-4 gap-4 px-6 py-2  h-[75vh] mb-12">
        {/* left side */}

        <div className="col-span-1 gap-4 rounded-[8px] border shadow-md h-[80vh] overflow-scroll">
          {clientLoading && <Loader className="mt-40" />}
          {allSessions.map((item, index) => (
            <div
              key={index}
              onClick={async () => {
                setCurrentSession(null);
                setSessionLoading(true);
                // sleep for 1 sec
                await new Promise((resolve) => setTimeout(resolve, 250));
                console.log(item);
                setSelectedIndexes({
                  ...selectedIndexes,
                  session: index,
                });
                setSessionLoading(false);

                setCurrentSession(item);
              }}
              className={`flex items-center justify-between py-3 px-4 border shadow-sm hover:shadow-md cursor-pointer ${
                index == 0 && "rounded-t-[8px]"
              } ${selectedIndexes.session === index && "bg-gray-200"}`}
            >
              <div className="flex items-center gap-2">
                {item.id}

                <ImageDS
                  imageKey={item.imageKey}
                  width={20} // Smaller size in dropdown
                  height={20} // Smaller size in dropdown
                  borderRadius={20}
                />
                <div>
                  <p className="text-sm">
                    {new Date(item.startTime).toDateString()}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* right side */}
        {sessionLoading ? (
          <Loader className="mt-40" />
        ) : (
          currentSession && (
            <div className="col-span-3 p-4 rounded-[8px] border shadow-md h-screen overflow-y-scroll">
              <div className="rounded-[8px] mb-16 h-screen ">
                <h2 className="pb-2 text-sm text-gray-500">
                  Session ID: {currentSession?.id}
                </h2>
                {currentSession?.audioUrl && (
                  <div className="bg-[#D9D9D9] flex items-center justify-center rounded-[8px] px-1 relative mb-2">
                    <audio controls className="w-full p-2  ">
                      <source src={currentSession.audioUrl} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
                {/* employee details */}
                <div className="flex items-center justify-between w-full p-2 mt-0">
                  {/* Left side: Text content */}
                  <div>
                    {/* Name */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        {selectedEmployee.fullName}
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>

                    {/* Role */}
                    <p className="text-[16px] mt-0 text-[#444454CC]">
                      Counselor
                    </p>

                    {/* Email */}
                    <div className="flex flex-row gap-4 mt-0">
                      <span className="text-[16px] text-[#444454CC]">
                        {selectedEmployee.email}
                      </span>
                    </div>
                  </div>

                  {/* Right side: Image */}
                  <div className="flex-shrink-0">
                    <ImageDS
                      imageKey={selectedEmployee.imageKey}
                      borderRadius={50}
                      width={80} // Adjust the size as needed
                      height={80}
                      className="object-cover"
                    />
                  </div>
                </div>

                {/* Client details */}
                <div className="w-full p-2 mt-3 ">
                  {/* name */}
                  <div className="flex items-center justify-between">
                    <h1 className="text-[24px] font-bold text-[#161D20]">
                      {currentSession?.client?.fullName}
                    </h1>
                    {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                  </div>
                  <p className="text-[16px] mt-0  text-[#444454CC]">Consumer</p>

                  {/* time & date */}
                  <div className="flex flex-row gap-4 mt-0">
                    <span className="text-[16px]  text-[#444454CC]">
                      {currentSession?.client?.email}
                    </span>
                  </div>
                  {/* role */}
                </div>
                {/* Audio Sharing */}
                <div className="w-full p-2 mt-3 ">
                  {/* name */}
                  <div className="flex items-center justify-between">
                    <h1 className="text-[24px] font-bold text-[#161D20]">
                      Session Info
                    </h1>
                    {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                  </div>
                  {/* time  */}
                  <div className="flex flex-col mt-1">
                    <div className="flex flex-row gap-10">
                      <span className="text-[16px]  text-[#444454CC]">
                        Started At :
                      </span>
                      <span className="text-[16px]  text-[#444454CC]">
                        {new Date(currentSession.startTime).toDateString() +
                          " " +
                          new Date(currentSession.startTime)
                            .toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")}
                      </span>
                    </div>
                    <div className="flex flex-row gap-10">
                      <span className="text-[16px]  text-[#444454CC]">
                        Ended At&nbsp;:
                      </span>
                      <span className="text-[16px]  text-[#444454CC]">
                        &nbsp;&nbsp;
                        {new Date(currentSession.endTime).toDateString() +
                          " " +
                          new Date(currentSession.endTime)
                            .toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")}
                      </span>
                    </div>
                  </div>
                </div>

                {currentSession?.notes && currentSession.notes.length > 0 && (
                  <div className="w-full p-2 mt-3 ">
                    {/* heading */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        Notes
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>
                    <ul
                      className=" w-full gap-1 px-1 ml-4"
                      style={{ listStyleType: "disc" }}
                    >
                      {currentSession.notes?.map((item, index) => (
                        <li
                          itemType=""
                          className="text-base list-item text-[#3c3c43cc]"
                          key={index}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {/* Your Question  */}
                {currentSession?.intelligence?.summary && (
                  <div className="w-full p-2 mt-3 ">
                    {/* heading */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        Summary
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>
                    <p className="text-[14px] text-[#444454CC] ml-2">
                      {currentSession?.intelligence?.summary}
                    </p>
                  </div>
                )}

                {currentSession.intelligence?.topics &&
                  currentSession.intelligence?.topics.length > 0 && (
                    <div className="w-full p-2 mt-3 ">
                      {/* heading */}
                      <div className="flex items-center justify-between">
                        <h1 className="text-[24px] font-bold text-[#161D20]">
                          Topics
                        </h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      <div className="w-full flex flex-wrap">
                        {currentSession.intelligence?.topics?.map(
                          (item, index) => (
                            <p
                              key={index}
                              className="text-[14px] w-fit text-[#444454CC] m-1 rounded-full bg-slate-100 px-2 py-1"
                            >
                              {item}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  )}
                <div className="w-full p-2 mt-3 ">
                  {/* heading */}
                  {(currentSession.intelligence?.data?.counselor &&
                    currentSession.intelligence?.data?.counselor?.length > 0) ||
                  (currentSession.intelligence?.data?.consumer &&
                    currentSession.intelligence?.data?.consumer?.length > 0) ? (
                    <>
                      <div className="flex items-center justify-between">
                        <h1 className="text-[24px] font-bold text-[#161D20]">
                          Data
                        </h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      <p className="mt-2">Counselor: </p>
                      <ul
                        className=" w-full gap-1 px-1 ml-4"
                        style={{ listStyleType: "disc" }}
                      >
                        {currentSession.intelligence?.data?.counselor?.map(
                          (item, index) => (
                            <li
                              itemType=""
                              className="text-[14px]  list-item text-[#444454CC]"
                              key={index}
                            >
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                      <p className="mt-2">Consumser: </p>
                      <ul
                        className="flex flex-col w-full gap-1 px-1 ml-4"
                        style={{ listStyleType: "disc" }}
                      >
                        {currentSession.intelligence?.data?.consumer?.map(
                          (item, index) => (
                            <li
                              className="text-[14px]  text-[#444454CC]"
                              key={index}
                            >
                              {item}
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {(currentSession.intelligence?.action?.counselor &&
                  currentSession.intelligence?.action?.counselor?.length > 0) ||
                (currentSession.intelligence?.action?.consumer &&
                  currentSession.intelligence?.action?.consumer?.length > 0) ? (
                  <div className="w-full p-2 mt-3 ">
                    {/* heading */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        Actions
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>
                    <p className="mt-2">Counselor: </p>
                    <ul
                      className="flex flex-col w-full gap-1 px-1 ml-4"
                      style={{ listStyleType: "disc" }}
                    >
                      {currentSession.intelligence?.action?.counselor?.map(
                        (item, index) => (
                          <li
                            className="text-[14px] text-[#444454CC]"
                            key={index}
                          >
                            {item}
                          </li>
                        )
                      )}
                    </ul>
                    <p className="mt-2">Consumser: </p>
                    <ul
                      className="flex flex-col w-full gap-1 px-1 ml-4"
                      style={{ listStyleType: "disc" }}
                    >
                      {currentSession.intelligence?.action?.consumer?.map(
                        (item, index) => (
                          <li
                            className="text-[14px]  text-[#444454CC]"
                            key={index}
                          >
                            {item}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
                {(currentSession.intelligence?.response?.counselor &&
                  currentSession.intelligence?.response?.counselor?.length >
                    0) ||
                (currentSession.intelligence?.response?.consumer &&
                  currentSession.intelligence?.response?.consumer?.length >
                    0) ? (
                  <div className="w-full p-2 mt-3 ">
                    {/* heading */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        Responses
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>
                    <p className="mt-2">Counselor: </p>
                    <ul
                      className="flex flex-col w-full gap-1 px-1 ml-4"
                      style={{ listStyleType: "disc" }}
                    >
                      {currentSession.intelligence?.response?.counselor?.map(
                        (item, index) => (
                          <li
                            className="text-[14px] text-[#444454CC]"
                            key={index}
                          >
                            {item}
                          </li>
                        )
                      )}
                    </ul>
                    <p className="mt-2">Consumser: </p>
                    <ul
                      className="flex flex-col w-full gap-1 px-1 ml-4"
                      style={{ listStyleType: "disc" }}
                    >
                      {currentSession.intelligence?.response?.consumer?.map(
                        (item, index) => (
                          <li
                            className="text-[14px]  text-[#444454CC]"
                            key={index}
                          >
                            {item}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
                {currentSession?.intelligence?.sentiment && (
                  <div className="w-full p-2 mt-3 ">
                    {/* heading */}
                    <div className="flex items-center justify-between">
                      <h1 className="text-[24px] font-bold text-[#161D20]">
                        Sentiments
                      </h1>
                      {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                    </div>
                    <p className="mt-2">
                      Overall Sentiment:{" "}
                      <span className="font-semibold">
                        {" "}
                        {String(
                          currentSession.intelligence?.sentiment
                        ).toLocaleUpperCase()}{" "}
                      </span>
                    </p>
                    <p className="mt-1 text-sm">
                      Neutral:{" "}
                      <span className="font-semibold">
                        {" "}
                        {String(
                          currentSession.intelligence?.sentiments?.neutral
                        ).toLocaleUpperCase()}{" "}
                      </span>
                    </p>
                    <p className="mt-1 text-sm">
                      Positive:{" "}
                      <span className="font-semibold">
                        {" "}
                        {String(
                          currentSession.intelligence?.sentiments?.positive
                        ).toLocaleUpperCase()}{" "}
                      </span>
                    </p>
                    <p className="mt-1 text-sm">
                      Negative:{" "}
                      <span className="font-semibold">
                        {" "}
                        {String(
                          currentSession.intelligence?.sentiments?.negative
                        ).toLocaleUpperCase()}{" "}
                      </span>
                    </p>
                  </div>
                )}
                {currentSession.intelligence?.transcript &&
                  currentSession.intelligence?.transcript.length > 0 && (
                    <>
                      <div className="flex items-center justify-between mt-4">
                        <h1 className="text-[24px] font-bold text-[#161D20]">
                          Transcript:
                        </h1>
                        {/* <BsThreeDotsVertical className="text-[#444454] cursor-pointer" /> */}
                      </div>
                      <div className="p-4">
                        <div className="flex flex-col">
                          {currentSession.intelligence?.transcript?.map(
                            (message, index) => (
                              <ChatMessage
                                key={index}
                                text={message.text}
                                speaker={message.speaker}
                                img={
                                  message.speaker == "consumer"
                                    ? currentSession?.client?.imageUrl
                                    : selectedEmployee.imageUrl
                                }
                              />
                            )
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SessionDetails;
